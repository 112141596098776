import axios from 'axios'
const baseUrl=process.env.REACT_APP_API_URL
const instance =axios.create({
    baseURL:baseUrl,
})

function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}

export async function buyCard(
    firstname, lastname, email,
    telephone, line1, line2, city,
    region, country, postcode,
    company, billToThis, code, 
    amount, refno, syncOnly,
    deliveryMode, sku, price, 
    qty, currency, theme
) {
    console.log("received data : ",
        firstname, lastname, email,
        telephone, line1, line2, city,
        region, country, postcode,
        company, billToThis, code, 
        amount, refno, syncOnly,
        deliveryMode, sku, price, 
        qty, currency, theme
    )
    if(
        !firstname || !lastname || !email ||
        !telephone || !line1 || !line2 || !city ||
        !region || !country || !postcode || 
        !billToThis || !code || 
        !amount || !refno || !syncOnly ||
        !sku || !price || !qty || !currency
    ) {
        console.log("Missing fields")
        return
    }
    theme = "";
    company = "";
    // creating the address
    let address = {}
    address.firstname = firstname
    address.lastname = lastname
    address.email = email
    address.telephone = telephone
    address.line1 = line1
    address.line2 = line2
    address.city = city
    address.region = region
    address.country = country
    address.postcode = postcode
    address.company = company
    address.billToThis = billToThis

    // creating billing
    let billing = {}
    billing.firstname = firstname
    billing.lastname = lastname
    billing.email = email
    billing.telephone = telephone
    billing.line1 = line1
    billing.line2 = line2
    billing.city = city
    billing.region = region
    billing.country = country
    billing.postcode = postcode
    billing.company = company

    // creating payments
    let payments = []
    let paymentObject = {}
    paymentObject.code = code
    paymentObject.amount = amount
    payments.push(paymentObject)

    // creating products
    let products = []
    let productsObject = {}
    productsObject.sku = sku
    productsObject.price = price
    productsObject.qty = qty
    productsObject.currency = currency
    productsObject.theme = theme
    products.push(productsObject)

    const buyResult = await instance.post("/buy",{
        address,
        billing,
        payments,
        refno,
        syncOnly,
        deliveryMode,
        products
    })
    console.log(buyResult);
    returnFunction(buyResult)
}