import './App.css';
import Home from './Components/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from './Components/Navbar';
import Gift from './Components/BuyingSection/Gift';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar/>
      <div className='backsideNavBar'></div>
        <Routes>
          <Route  path="/" element={<Home/>}/>
          <Route path="/ProductBuy/:id" element={<Gift/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
