import axios from 'axios'
const baseUrl=process.env.REACT_APP_API_URL
const instance =axios.create({
    baseURL:baseUrl,
})

function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}

export async function getAllCards(){
    const result =await instance.get("/getCards")
    return returnFunction(result);
}

export async function getSingleCard(id){
    const result = await instance.get(`/getCard/${id}`)
    return returnFunction(result);
}