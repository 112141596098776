import React,{useState,useEffect} from 'react'
import Card from './Cards'
import { Link } from 'react-router-dom';
import { getAllCards } from '../services/getCards';
const Home = () => {

    // display the content of these cards using the map function
    const [cards,setCards] = useState([]);
    useEffect(() => {
        let a = async() => {
            let b = await getAllCards();
            if(b) {
                setCards(b);
                // console.log(b)
            }
        };
        a();
    },[])
    return (
        <div className='container text-center'>
            <div className="row justify-content-evenly">
                {cards.map((card,index) => {
                    return (
                        <div className="col-4">
                            <Link to={`/ProductBuy/${card._id}`}>
                            {/* add card id to the props when mapping actual data */}
                            {/* might need to remove other fields. confirm it later with a backend guy */}
                            <Card CardImg={card.images.base} CardName={card.name} Desc={card.Desc} tagLine={card.tagline} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Home