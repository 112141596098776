import React,{useState,useEffect} from 'react'
import { getSingleCard } from '../../services/getCards';
import { buyCard } from '../../services/orders';
import {v4 as uuid} from "uuid";

const GiftCard = (props) => {
    const [isShown,setIsShown]=useState(false)

    const [buyData,setBuyData] = useState({
        name : "", // separate first name and last name
        email : "",
        telephone : "",
        line1 : "",
        line2 : "",
        city : "",
        region : "",
        country : "IN",
        postcode : "",
        company : "",
        billToThis : true,
        code : "svc",
        amount : 0,
        refno : "", 
        syncOnly : true,
        deliveryMode : "API", // should i give default value of API or take value from bullets
        sku : props.sku,
        price : 0,
        qty : 1,
        currency : "356",
        theme : "",
        upi : ""
    })

    const handleSubmit = async() => {
        let nameArray = buyData.name.split(' ')
        // error correction for clashing uuid
        buyData.refno = uuid().replace(/-/g,'')
        buyData.refno = buyData.refno.slice(0,25);
        buyData.amount = (buyData.amount)*(buyData.qty);
        if(nameArray.length <= 1) {
            console.log("Please enter full name")
            return
        }
        let firstname = nameArray[0];
        let lastname = nameArray.slice(1).join(' ');
        setBuyData({...buyData,price : buyData.amount})
        console.log(buyData)
        console.log("sent data",          
            firstname,lastname,buyData.email,buyData.telephone,
            buyData.line1,buyData.line2,buyData.city,buyData.region,
            buyData.country,buyData.postcode,buyData.company,
            buyData.billToThis,buyData.code,buyData.amount,
            buyData.refno,buyData.syncOnly,buyData.deliveryMode,
            buyData.sku,buyData.price,buyData.qty,buyData.currency,
            buyData.theme
        );
        const buyResult = await buyCard(
            firstname,lastname,buyData.email,buyData.telephone,
            buyData.line1,buyData.line2,buyData.city,buyData.region,
            buyData.country,buyData.postcode,buyData.company,
            buyData.billToThis,buyData.code,buyData.amount,
            buyData.refno,buyData.syncOnly,buyData.deliveryMode,
            buyData.sku,buyData.price,buyData.qty,buyData.currency,
            buyData.theme
        )
        console.log(buyResult);
        // take some action based on result
    }

    return (
        <div className='MainCotainer container'>
            <div className="CardsDeatails row">
                <div className="titleHeading">
                    <h3>E-Gift Card</h3>
                </div>
                <div className="col-4">
                    <img className='CardImage' src={props.Image} alt="" width="100%" />
                </div>
                <div className="col-4">
                    <div className="InputContainer">
                        <label htmlFor="">Enter denomination</label>
                        <input type="number" onChange={(e) => {
                            setBuyData({...buyData,amount : e.target.value})
                        }}/>
                        <span>Min:₹{props.min} Max:₹{props.max}</span>
                    </div>
                    <div className="InputContainer">
                        <label htmlFor="">Quantity</label>
                        <input type="number" onChange={(e) => setBuyData({...buyData,qty : e.target.value})}/>
                    </div>
                    {/* <div className="BuyingOption">
                        <div className="CheckBox">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label htmlFor="">Send as Gift</label>
                        </div>
                        <div className="CheckBox">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                            <label htmlFor="">Buy for Self(This E-gift card will be added to your account)</label>
                        </div>
                    </div> */}
                </div>
                <div className="col-4">
                    <div>Offers </div>
                    <div className="Offers">
                        Use Code:{props.sku}
                    </div>
                </div>
            </div>
            <div className="Heading row">
                Delivery Mode
            </div>
            <div className="DeliveryOptions">
                <div className="CheckBox">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label htmlFor="">Email</label>
                </div>
                <div className="CheckBox">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label htmlFor="">SMS</label>
                </div>
                <div className="CheckBox">
                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label htmlFor="">Both</label>
                </div>
            </div>
            <div className="row">
                Gifting Details
            </div>
            <div className="Details row">
                <div className="col-4">
                    <input type="text" placeholder="Receiver's Name" onChange={(e) => setBuyData({...buyData,name : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Receiver's Email" onChange={(e) => setBuyData({...buyData,email : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Receiver's Mobile Number" onChange={(e) => setBuyData({...buyData,telephone : "+91" + e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="textarea" placeholder="Message for Receiver" />
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Address line 1" onChange={(e) => setBuyData({...buyData,line1 : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Address line 2" onChange={(e) => setBuyData({...buyData,line2 : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="City" onChange={(e) => setBuyData({...buyData,city : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Region" onChange={(e) => setBuyData({...buyData,region : e.target.value})}/>
                </div>
                <div className="col-4">
                    <input type="text" placeholder="Postcode" onChange={(e) => setBuyData({...buyData,postcode : e.target.value})}/>
                </div>
            </div>
            {isShown &&
            <div className="PaymentSection">
                <div>
                <label htmlFor="">Enter Your UPI-ID</label>
                <input type="text" onChange={(e) => setBuyData({...buyData,upi : e.target.value})}/>
                <button onClick={handleSubmit}>Verify</button>
                </div>
            </div>
            }
            <div className="PayNow Btn row">
                <button onClick={()=>setIsShown(true)}>Pay Now</button>
            </div>
        </div>
    )
}

export default GiftCard