import React from 'react'
import './style.css'
const Cards = (props) => {
  return (
    <div className='Cards'>
        <div className="ImgContainer">
            <img src={props.CardImg} alt="" />
        </div>
        <div className="descContainer">
            <div className="CardName">{props.CardName}</div>
            <div className="Desc">{props.Desc}</div>
            <div className="tagLine">{props.tagLine}</div>
        </div>
    </div>
  )
}

export default Cards