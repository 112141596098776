import React, { useEffect, useState } from 'react'
import GiftCard from './GiftCard'
import { useParams } from 'react-router-dom'
import {getSingleCard} from '../../services/getCards'
const Gift = () => {
  const image=useParams();
  console.log(image.id)
  const [card,setCard]=useState()
  useEffect(() => {
    let a = async() => {
      let cardDetails = await getSingleCard(image.id);
      setCard(cardDetails);
    };
    a();
  },[])
  // console.log(card)
  return (
    <div>
        {card?
        <GiftCard Image={card.images.mobile} min={card.minPrice} max={card.maxPrice} sku={card.sku}/>
        :
        <>
        <div style={{width:"100%",height:"0",paddingBottom:"100%",position:"relative"}}><iframe src="https://giphy.com/embed/3o7bu3XilJ5BOiSGic" width="100%" height="100%" style={{position:"absolute"}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/youtube-loading-gif-3o7bu3XilJ5BOiSGic">via GIPHY</a></p>
        </>

      }
        {/* <GiftCard CardImage={ `https://picsum.photos/id/1/500/200`}/> */}
    </div>
  )
}

export default Gift