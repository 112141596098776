import React,{useState} from "react";
import { useRef } from "react";
import logo from "../assets/Logo.svg";
import search from '../assets/Search.svg'
import { Link } from "react-router-dom";
import Category from "./Category";
export default function Navbar() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  const [isShown,setIsShown]=useState(false)
  const Dropdown=()=>{
    isShown?setIsShown(false):setIsShown(true)
  }
  return (
    <div className="Navbar">
      <div className="header"> 
      {/* this div contains the logo of the company at the navbar  */}
        <div className="Navbar-LogoName">
          <img src={logo} alt="" />
          <Link to="/">Sellular</Link>
        </div>
        {/* <button className="Btn-Bars" onClick={showNavbar}>
        <i className="fa-solid fa-bars"></i>
        </button> */}
        <nav ref={navRef}>
        {/* <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <i className="fa-solid fa-xmark"></i>
        </button> */}
        <div className="SearchBox">
            <img src={search} alt="" />
            <input type="text" placeholder="Search By"/>
        </div>
        </nav>
        <a className="loginSignUp">
            Login/SignUp
          </a>
      </div>
      {/* <div className="CategoryDropdown">
        <button onClick={Dropdown}>Categories </button>
      </div>
      {isShown&&
      <div className="DropdownContainer">
        <Category/>
      </div>
      } */}
    </div>
  );
}
