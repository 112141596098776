import axios from 'axios'
const baseUrl=process.env.REACT_APP_API_URL
const instance =axios.create({
    baseURL:baseUrl,
})

function returnFunction(result) {
    if (result.data) {
        return result.data
    } else {
        console.log(result)
    }
}

export async function getAllCategories() {
    let result = await instance.get('/getCategories');
    return returnFunction(result);
}